import {stats as statsWidget } from 'instantsearch.js/es/widgets';

export const stats = () => statsWidget({
    container: '#stats',
    templates: {
        text: `
          {{^areHitsSorted}}
            {{#hasNoResults}}No results{{/hasNoResults}}
            {{#hasOneResult}}1{{/hasOneResult}}
            {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}{{/hasManyResults}}
          {{/areHitsSorted}}
        `,
    },
});
