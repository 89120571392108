import instantSearch from "instantsearch.js";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { searchBox } from "./widgets/SearchBox";

import Utils from "./Utils";
import { newLocationsFilters } from "./widgets/NewLocationsFilters";

const HomeSearcher = {
    searchContainerEl: {
        selector: "#home-instantsearch",
    },

    searchBoxEl: {
        desktopSelector: "#search-box",
        mobileSelector: "#mobile-search-box",
    },

    locationInputEl: {
        selector: "#location",
    },

    homeTagEl: {
        selector: ".home-tag-js",
    },

    mobileSearchFormEl: {
        selector: ".mobile-search-form-js",
    },

    searchButtonEl: {
        selector: ".job-offer-button-js",
    },

    homeSearchButtonEl: {
        selector: ".home-search-button-js",
    },

    mobileItem: {
        event: "mobile-item-clicked",
    },

    init() {
        const searchClient = this.setUpInstantsearch();
        this.setListeners(searchClient);
    },

    setListeners(searchClient) {
        Utils.setDefaultSearcherListeners(searchClient);
        $(this.homeTagEl.selector).on("click", (ev) =>
            this.homeTagClick(ev, searchClient)
        );
        $(this.mobileSearchFormEl.selector).on("submit", (ev) =>
            this.homeSearchClickEv(ev)
        );
        $(this.homeSearchButtonEl.selector).on("click", (ev) =>
            this.homeSearchClickEv(ev)
        );
        document.addEventListener(this.mobileItem.event, (ev) =>
            this.mobileItemClicked(ev, searchClient)
        );
        $(
            `${this.searchBoxEl.desktopSelector}, ${this.locationInputEl.selector}`
        ).on("keydown", (ev) => this.homeKeyDownEv(ev, searchClient));
    },

    homeKeyDownEv(ev) {
        if (ev.key === "Enter") {
            setTimeout(() => {
                this.homeSearchClickEv(ev);
            }, 500);
        }
    },

    mobileItemClicked(ev) {
        $(this.searchBoxEl.desktopSelector).val(ev.detail);
        this.homeSearchClickEv(ev);
    },

    homeSearchClickEv(ev) {
        ev.preventDefault();
        Utils.buildLocationLists();
        const url = Utils.buildSearchUrl();
        sessionStorage.setItem(
            "homeSuggestions",
            JSON.stringify(window.searchResults)
        );
        window.location.href = url;
        sessionStorage.setItem("homeSearch", "true");
    },

    homeTagClick(ev, searchClient) {
        const value = ev.target.dataset.value;
        $(this.searchBoxEl.desktopSelector).val(value);
        $(this.searchBoxEl.mobileSelector).val(value);
        searchClient.helper.setQuery(value).search();
    },

    setUpInstantsearch() {
        sessionStorage.setItem("indexUiState", "");
        const searchClient = instantSearch({
            indexName: import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX,
            searchClient: instantMeiliSearch(
                import.meta.env.VITE_MEILISEARCH_HOST,
                import.meta.env.VITE_MEILISEARCH_KEY,
                {
                    keepZeroFacets: true,
                    paginationTotalHits: 1000,
                }
            ),
            routing: {
                stateMapping: {
                    stateToRoute(uiState) {
                        const indexUiState =
                            uiState[
                            import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX
                            ];
                    },
                    routeToState(routeState) { },
                },
            },
        });

        searchClient.addWidgets([searchBox(), newLocationsFilters()]);
        window.selectedLocations = [];
        searchClient.start();

        return searchClient;
    },
};

export default HomeSearcher;
