import "./bootstrap";
import jQuery from "jquery";
import.meta.glob([
    '../images/**',
    '../fonts/**',
]);
try {
    window.$ = window.jQuery = jQuery;
} catch (e) { }

import intlTelInput from "intl-tel-input";
import * as FilePond from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import ViewHandler from "./ViewHandler";

window.intlTelInput = intlTelInput;
window.FilePond = FilePond;
window.jobOffersIndex = import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX;
FilePond.registerPlugin(FilePondPluginFileValidateType);
FilePond.registerPlugin(FilePondPluginFileValidateSize);

// Always last item
ViewHandler.init();
