import GlideCarouselController from "./Controllers/GlideCarouselController";
import AlpineController from "./Controllers/AlpineController";
import ApplyFormController from "./Controllers/ApplyFormController";
import ScrollController from "./Controllers/ScrollController";
import JobOffersSearcher from "./InstantSearch/JobOffersSearcher";
import HomeSearcher from "./InstantSearch/HomeSearcher";
import DataLayerController from "./Controllers/DataLayerController";
import UrlDataController from "./Controllers/UrlDataController";
// import Observer from "./Controllers/ViewportAnimationController";
import MultiApplyFormController from "./Controllers/MultiApplyFormController";

const ViewHandler = {
    init() {
        console.log('viewHandler');
        UrlDataController.init();
        AlpineController.init();
        //MultiApplyFormController.init();

        const { pageType } = window;
        DataLayerController.init(pageType);
        switch (pageType) {
            case "home":
                GlideCarouselController.init();
                HomeSearcher.init();
                // const scrollElements = document.querySelectorAll('[entrance]')
                // scrollElements.forEach((el) => Observer.observe(el))
                break;
            case "jobs":
                JobOffersSearcher.init();
                ScrollController.scrollUp();
                break;
            case "job-detail":
                ApplyFormController.init();
                break;
            case "stories":
                GlideCarouselController.init();
                break;
        }
    },
};

export default ViewHandler;
