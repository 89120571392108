const UrlDataController = {
    urlParamKeys: ["utm_source", "utm_campaign", "utm_medium", "UTM_source", "UTM_campaign", "UTM_medium"],

    localStorageKey: "visitData",

    init() {
        const urlParams = this.getUrlParams();
        this.saveUrlParamsInLocalStorage(urlParams);
    },

    getUrlParams() {
        const queryString = window.location.search;
        return new URLSearchParams(queryString);
    },

    saveUrlParamsInLocalStorage(urlParams) {
        let visitData =
            JSON.parse(localStorage.getItem(this.localStorageKey)) ?? {};
        this.urlParamKeys.forEach((paramKey) => {
            const param = urlParams.get(paramKey);
            if (param) {
                visitData[paramKey] = param;
            }
        });
        localStorage.setItem(this.localStorageKey, JSON.stringify(visitData));
    },
};

export default UrlDataController;
