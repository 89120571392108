import { connectRefinementList } from "instantsearch.js/es/connectors";
import Utils from "../Utils";

const renderRefinementList = (renderOptions, isFirstRender) => {
    const { items, refine, widgetParams } = renderOptions;
    const { data } = renderOptions.widgetParams;
    let updatedItems = items;
    if (isFirstRender) {
        const ul = document.createElement("ul");
        widgetParams.container.appendChild(ul);
    }

    if (
        !isFirstRender &&
        updatedItems.length !== window[data["windowInitial"]].length
    ) {
        updatedItems = addMissing(updatedItems, data);
    }

    updatedItems.sort((a, b) => a.value.localeCompare(b.value));

    if (window.initialLoad && !isFirstRender) {
        updatedItems = setUpQueryParamsFromUrl(updatedItems, 0, data);
        if (data["lastRefinement"]) window.initialLoad = false;
    }
    updatedItems = fixUrlRefinementBug(updatedItems, data);

    const selectedNumber = Utils.getSelectedFiltersNumber(updatedItems);
    Utils.setSelectedRefinementsNumber(
        selectedNumber,
        data["selectedItemCounterClass"]
    );

    widgetParams.container.querySelector("ul").innerHTML = updatedItems
        .map(
            (item) => `
               <li class="cursor-pointer filter-item flex items-start mb-4 ${
                   item.isRefined ? "active" : ""
               } ${data["refinementFilterJsClass"]}"
                    data-value="${item.value}">
                    <input type="checkbox" class="text-blue w-5 h-5 mt-1 rounded mr-2 border-grey-500" ${
                        item.isRefined ? "checked" : ""
                    }>
                    <label class="font-semibold">${item.label}</label>

             </li>
      `
        )
        .join("");

    [
        ...widgetParams.container.querySelectorAll(
            `li.${data["refinementFilterJsClass"]}`
        ),
    ].forEach((element) => {
        element.addEventListener("click", (event) => {
            event.preventDefault();

            window.scrollTo({ top: 0, behavior: "smooth" });
            window.searched = true;
            const value = event.currentTarget.dataset.value;
            refine(value);

            const index = window[data["windowSelected"]].findIndex(
                (item) => item === value
            );
            if (index === -1) {
                window[data["windowSelected"]].push(value);
            } else {
                window[data["windowSelected"]].splice(index, 1);
            }
        });
    });
};

const addMissing = (items, data) => {
    for (const item in window[data["windowInitial"]]) {
        if (!items.filter((e) => e.value === item).length > 0) {
            const obj = {
                count: 0,
                highlighted: item,
                isRefined: false,
                label: item,
                value: item,
            };
            items.push(obj);
        }
    }

    return items;
};

const setUpQueryParamsFromUrl = (items, index, data) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param = urlParams.get(
        `${import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX}[refinementList][${
            data["attribute"]
        }][${index}]`
    );
    if (param) {
        const x = items.findIndex((obj) => obj.value === param);
        if (x !== -1) {
            items[x].isRefined = true;
            window[data["windowSelected"]].push(param);
        }
        setUpQueryParamsFromUrl(items, index + 1, data);
    }

    return items;
};

const fixUrlRefinementBug = (items, data) => {
    window[data["windowSelected"]].forEach((item) => {
        const x = items.findIndex((obj) => obj.value === item);
        items[x].isRefined = x !== -1;
    });
    return items;
};

const customRefinementList = connectRefinementList(renderRefinementList);

export const refinementComponent = (data) =>
    customRefinementList({
        container: document.querySelector(data["componentSelector"]),
        attribute: data["attribute"],
        limit: 1000,
        sortBy: ["name:asc"],
        data: data,
    });
