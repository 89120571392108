const DataLayerController = {
    formPages: ["job-detail", "apply", "success"],
    locationEl: {
        selector: "#location",
    },
    resultsEl: {
        selector: "#stats span",
    },
    queryEl: {
        selector: "#search-box",
    },

    init(pageType) {
        this.checkInitiateFromLocationRemove(pageType);
        this.startEventOnJobDetailPage(pageType);
    },

    checkInitiateFromLocationRemove(pageType) {
        if (!this.formPages.includes(pageType)) {
            sessionStorage.setItem("initiate_from_location", null);
        }
    },

    startEventOnJobDetailPage(pageType) {
        if (pageType === "job-detail") {
            window.eventSent = false;
            $("#apply-form-js input").on("input", (ev) =>
                this.jobOfferFormInputEvent(ev)
            );
        }
    },

    jobOfferFormInputEvent(ev) {
        if (!window.eventSent) {
            const source = this.getVisitDataSource();
            const fromLocation = sessionStorage.getItem(
                "initiate_from_location"
            );
            const data = {
                event: "application",
                step: "start",
                source: source,
                type: "job",
                initiate_from_page: window.location.pathname,
                initiate_from_location: fromLocation,
            };
            window.dataLayer.push(data);
            window.eventSent = true;
        }
    },

    searchDataPush(query = false) {
        const jobSearchOrigin = this.getSearchOrigin();
        setTimeout(() => {
            const locationQuery = this.getLocationQuery();
            const resultsCount = this.getResultsCount();
            const data = {
                event: "job_search",
                job_search_term: query ? query : this.getQuery(),
                job_search_location: locationQuery,
                job_search_result_count: this.parseResultsCount(resultsCount),
                job_search_origin: jobSearchOrigin,
            };
            window.dataLayer.push(data);
        }, 1000);
    },

    getLocationQuery() {
        return $(this.locationEl.selector).val().trim();
    },

    getResultsCount() {
        return $(this.resultsEl.selector).text().trim();
    },

    getQuery() {
        return $(this.queryEl.selector).val().trim();
    },

    getVisitDataSource() {
        const visitData = JSON.parse(localStorage.getItem("visitData"));
        return visitData["utm_source"] ? visitData["utm_source"] : "";
    },

    getSearchOrigin() {
        const { pageType } = window;
        switch (pageType) {
            case "jobs":
                let result;
                const { homeSearch } = window;
                if (homeSearch) {
                    result = "home";
                    window.homeSearch = false;
                } else {
                    result = "jobs";
                }
                return result;
            case "brands":
                return "brands";
        }
    },

    parseResultsCount(count) {
        const parsed = Number(count);
        if (Number.isNaN(parsed)) {
            return count;
        }

        return parsed;
    },
};

export default DataLayerController;
