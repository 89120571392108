import Glide from "@glidejs/glide";

const GlideCarouselController = {
    init() {
        if (document.querySelector("#last-jobs")) {
            new Glide("#last-jobs", {
                type: "carousel",
                perView: 4,
                breakpoints: {
                    700: {
                        perView: 1,
                        focusAt: "center",
                    },
                },
            }).mount();
        }
        if (document.querySelector("#positive-impacts")) {
            new Glide("#positive-impacts", {
                type: "carousel",
                perView: 4,
                autoplay: 1,
                animationDuration: 10000,
                animationTimingFunc: "linear",
                dragThreshold: false,
                breakpoints: {
                    700: {
                        perView: 1.2,
                        focusAt: "center",
                    },
                },
            }).mount();
        }
        if (document.querySelector("#carousel-people")) {
            new Glide("#carousel-people", {
                type: "carousel",
                focusAt: "center",
                perView: 1.5,
                gap: 50,
                breakpoints: {
                    700: {
                        perView: 1.2,
                        focusAt: "center",
                    },
                },
            }).mount();
        }
        if (document.querySelector("#stories")) {
            var glide = new Glide("#stories", {
                type: "carousel",
                focusAt: "center",
                perView: 4.2,
                dragThreshold: false,
                animationDuration: 300,
                gap: 0,
                breakpoints: {
                    700: {
                        perView: 1.2,
                        gap: 10,
                        focusAt: "center",
                    },
                },
            });

            glide.mount();
        }
    },
};

export default GlideCarouselController;
