const ScrollController = {
    scrollUp() {
        const btn = document.getElementById("scroll-top");

        window.addEventListener("scroll", () => {
            const height =
                document.getElementById("infinite-hits").offsetHeight;

            let scrolled = window.scrollY;
            if (scrolled > 300 && scrolled < height) {
                btn.classList.remove("scale-0", "opacity-0");
                btn.classList.add("scale-100", "opacity-100");
            } else {
                btn.classList.remove("scale-100", "opacity-100");
                btn.classList.add("scale-0", "opacity-0");
            }
        });
    },
};

export default ScrollController;
