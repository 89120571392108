// Livewire v3 upgrade changes fallowing https://livewire.laravel.com/docs/upgrading#including-via-js-bundle
const AlpineController = {
    init() {
        Alpine.data("scrolltop", () => ({
            init() {
                let lastKnownScrollPosition = 0;
                let ticking = false;

                function doSomething(scrollPos) {
                    if (scrollPos > 100) {
                        document
                            .getElementById("scroll-top")
                            .classList.remove("translate-y-20");
                    } else {
                        document
                            .getElementById("scroll-top")
                            .classList.add("translate-y-20");
                    }
                }

                document.addEventListener("scroll", function (e) {
                    lastKnownScrollPosition = window.scrollY;

                    if (!ticking) {
                        window.requestAnimationFrame(function () {
                            doSomething(lastKnownScrollPosition);
                            ticking = false;
                        });

                        ticking = true;
                    }
                });
            },
        }));
    },
};

export default AlpineController;
