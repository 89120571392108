import { infiniteHits as infiniteHitsWidget } from "instantsearch.js/es/widgets";

export const infiniteHits = () =>
    infiniteHitsWidget({
        container: "#infinite-hits",
        escapeHTML: false,
        transformItems(hits) {
            window.searched = true;
            return hits.map((item) => ({
                ...item,
                desc: decodeEntities(item.description),
            }));
        },
        templates: {
            item: (data) => {
                return `
                    <article class="job relative flex flex-col justify-items-stretch p-5 pb-24 outline h-full outline-2 hover:outline-4 hover:outline-blue shadow-blue  transition-all cursor-pointer bg-white outline-rose-600 rounded-xl">
                        <a href='/jobs/${data["id"]}'>
                            <h3 class="text-lg leading-none mb-2 capitalize"> ${
                                data["title"]
                            }</h3>
                            <div class="flex border-b border-rose-600 mb-5">
                                <span class="opacity-75 pb-2 my-2">
                                    ${data["locations"]
                                        .map((city) => {
                                            if (city.length > 0) {
                                                city = city.substring(
                                                    city.indexOf(">") + 1
                                                );
                                                return ` ${city}`;
                                            }
                                        })
                                        .join(", ")}
                                </span>
                           </div>
                            <p class="md:block hidden font-thin leading-none mb-2">
                            ${
                                data["desc"].length > 125
                                    ? data["desc"].substring(0, 125) + "..."
                                    : data["desc"]
                            }
                            </p>
                            <div class="flex flex-wrap items-center mt-5">
                                ${data["skills"]
                                    .map((skill) => {
                                        return ` <span class="p-1 text-sm bg-rose mr-1 mb-1 rounded-md">
                                                ${skill}
                                            </span>`;
                                    })
                                    .join("")}
                            </div>
                            <span class="text-sm opacity-50 mt-4 md:mt-0 mb-5">
                                ${daysAgo(data["web_published_date_days_ago"])}
                            </span>
                        </a>
                        ${
                            window.multi_apply_enabled
                                ? `<div class="absolute bottom-5 left-5 right-5 flex justify-between items-center mt-5">
                        <a href='/jobs/${
                            data["id"]
                        }' class="rounded-full border border-blue border-opacity-50 transition-all hover:border-opacity-100 py-3 px-6 text-center w-1/2 mr-1">
                                View details
                        </a>
                        <button class="rounded-full ml-1 border py-3 px-6 text-center w-1/2 bg-blue hover:bg-opacity-90 active:scale-95 transition-all text-white apply-js ${
                            window.selectedJobs.includes(data["id"])
                                ? "hidden"
                                : ""
                        }"
                                data-jobId="${data["id"]}"
                                onclick="var event = new CustomEvent('job-selected', { 'detail':${
                                    data["id"]
                                }}); document.dispatchEvent(event);"
                        >
                            Apply
                        </button>
                        <button data-jobId="${data["id"]}"
                                class="rounded-full flex items-center justify-center ml-1 py-3 px-6 text-center w-1/2 bg-blue bg-opacity-10 added-js ${
                                    window.selectedJobs.includes(data["id"])
                                        ? ""
                                        : "hidden"
                                }"
                        >
                            Added
                                <svg class="ml-2" width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_604_774)">
                                        <path d="M19.2921 22.0001H6.14465C2.7582 22.0001 0 19.2405 0 15.8524V9.02241C0 8.60081 0.344775 8.25586 0.766166 8.25586C1.18756 8.25586 1.53233 8.60081 1.53233 9.02241V15.8524C1.53233 18.3973 3.60098 20.467 6.14465 20.467H19.2921C21.5906 20.467 23.4677 18.5966 23.4677 16.2893V9.02241C23.4677 8.60081 23.8124 8.25586 24.2338 8.25586C24.6552 8.25586 25 8.60081 25 9.02241V16.2893C25 19.4398 22.441 22.0001 19.2921 22.0001Z" fill="#272674"/>
                                        <path d="M12.4962 16.5115C17.0535 16.5115 20.7478 12.8153 20.7478 8.25575C20.7478 3.69623 17.0535 0 12.4962 0C7.939 0 4.24463 3.69623 4.24463 8.25575C4.24463 12.8153 7.939 16.5115 12.4962 16.5115Z" fill="#7DDEB8"/>
                                        <path d="M10.9026 11.7281C10.7034 11.7281 10.5119 11.6515 10.3586 11.5058L8.93358 10.0801C8.63477 9.7811 8.63477 9.29818 8.93358 8.99922C9.23238 8.70027 9.71507 8.70027 10.0139 8.99922L10.9026 9.88842L16.1126 4.67588C16.4114 4.37692 16.894 4.37692 17.1928 4.67588C17.4917 4.97483 17.4917 5.45776 17.1928 5.75671L11.4389 11.5135C11.2857 11.6668 11.0942 11.7358 10.895 11.7358L10.9026 11.7281Z" fill="white"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_604_774">
                                        <rect width="25" height="22" fill="white"/>
                                        </clipPath>
                                        </defs>
                                </svg>

                        </button>
                    </div>`
                                : ""
                        }
                    </article>
                `;
            },
        },
    });
var decodeEntities = (function () {
    var element = document.createElement("div");

    function decodeHTMLEntities(str) {
        if (str && typeof str === "string") {
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = "";
        }

        return str;
    }

    return decodeHTMLEntities;
})();

function daysAgo(date) {
    switch (date) {
        case 0:
            return "Today";
        case 1:
            return date + " day ago";
        default:
            return date + " days ago";
    }
}
