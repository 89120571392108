const ApplyFormController = {
    init() {
        const smallApplyForm = document.querySelector("#sticky-apply");
        if (smallApplyForm) {
            document.addEventListener("scroll", stickyApplyFrom);
        }
        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute("href")).scrollIntoView({
                    behavior: "smooth",
                });
            });
        });

        function stickyApplyFrom() {
            const pixels = window.pageYOffset;
            var formOffset = offset(smallApplyForm);
            if (formOffset.top > pixels) {
                smallApplyForm.classList.remove("isSticky");
            } else {
                smallApplyForm.classList.add("isSticky");
            }
        }

        function offset(el) {
            var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return {
                top: rect.top + scrollTop,
                left: rect.left + scrollLeft
            };
        }
    }
};

export default ApplyFormController;
